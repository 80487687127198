<template>
    <div>
        Do you already have a Nuvolos account?
        <div class="text-center mt-4">
            <v-btn :to="{ name: 'login', query: { targetUrl: $route.path } }" text class="mr-4">Yes</v-btn>
            <v-btn :to="{ name: 'signup-options' }" text>No</v-btn>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SignupHome',
    data() {
        return {}
    },
    created() {},
    methods: {},
    computed: {},
    watch: {},
    updated() {}
}
</script>
